import React, { useState } from "react";
import { LogoDark } from "../assets";
import "./Main.css";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const headerItemClass = "header-item";

  return (
    <header className="site-header" role="navigation">
      <div className="site-header-inner">
        <a href="https://www.ampere-media.com">
          <img src={LogoDark} alt="Logo"/>
        </a>

        <div
          className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        <nav className={`header-nav_header ${isMenuOpen ? "open" : ""}`}>
          <div className={headerItemClass}>
            <Link
              to="home"
              smooth={true}
              className={headerItemClass}
              onClick={closeMenu}
            >
              Home
            </Link>
          </div>

          <div className={headerItemClass}>
            <Link
              to="about"
              smooth={true}
              className={headerItemClass}
              onClick={closeMenu}
            >
              About Us
            </Link>
          </div>

          <div className={headerItemClass}>
            <Link
              to="testmony"
              smooth={true}
              className={headerItemClass}
              onClick={closeMenu}
            >
              Testimonial
            </Link>
          </div>

          <div className={headerItemClass}>
            <Link
              to="services"
              smooth={true}
              className={headerItemClass}
              onClick={closeMenu}
            >
              Services
            </Link>
          </div>

          <div className={headerItemClass}>
            <Link
              to="contact"
              smooth={true}
              className={headerItemClass}
              onClick={closeMenu}
            >
              Contact Us
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
