import React from "react";
import "./Main.css";

const Header = () => {
  const isVideoSupported =
    typeof document.createElement("video").canPlayType === "function";

  return (
    <div
      className="layout layout_layout--bottom-xl__LoXlO layout-top"
      data-theme
      id="home"
    >
      <div className="c-hero c-hero dev-static-hero">

        <div className="c-hero__content">
          <h1 className="heading-text">
            Unique Facebook Ads, Standout <br /> Website Design – Outshine{" "}
            <br /> The Competition.
          </h1>
          <h2 className="t-lede">
            We've empowered social media marketing agencies to establish their
            brand identity and successfully <br /> attain their objectives. Let
            us do the same for you.
          </h2>

          <div className="c-hero__actions">
            <a
              className="o-button o-button--primary"
              data-gtm-key="button"
              href="https://odavqeqragw.typeform.com/to/DakWHqZy"
              target="_blank"
            >
              <span>Book a demo </span>
            </a>
          </div>
        </div>

        <div className="dev-static-hero__scroll">
          <div className="dev-static-hero__scroll-wrapper">
            <div className="dev-static-hero__grad">
              <div className="dev-static-hero__grad-inner" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isVideoSupported ? (
                <video autoPlay muted loop>
                  <source src="/Videos/Vid.mp4" />
                </video>
              ) : (
                <p>Your browser does not support the video tag.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
