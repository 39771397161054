import React from "react";
import { LogoDark, Instagram, Mail } from "../assets";
import "./Main.css";

const Footer = () => {
  return (
    <footer className="footer-nav" id="contact">
      <div className="footer-nav-section">
        <div className="footer-nav-brand">
          <a href="https://www.ampere-media.com">
            <img className="footer-icon" src={LogoDark} />
          </a>

          <div className="footer-nav-social">
            <a
              href="mailto:info@ampere-media.com"
              className="social-nav-link"
              target="_blank"
            >
              <img src={Mail} />
            </a>

            <a
              className="social-nav-link"
              href="https://www.instagram.com/amperemedia/"
              target="_blank"
            >
              <img src={Instagram} />
            </a>
          </div>
        </div>
      </div>

      <hr />

      <div className="footer-nav-section">
        <div className="footer-nav-compliance">
          <small>Copyright © 2024 Ampere Media - All Rights Reserved</small>
          <small>Powered by Ampere Media</small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
