import React from "react";
import { Consultation, Plan, Push, Founder } from "../assets";

const Services = () => {
  return (
    <>
      <div
        className="layout layout_layout--bottom-0__sL_3a layout-top"
        data-theme
        id="services"
      >
        <div className="section-heading">
          <div className="section-heading-parent">
            <h2 className="text-heading">Services you'll be getting</h2>
          </div>
        </div>
      </div>
      <div className="layout layout_layout--bottom-xl__LoXlO layout_layout--top-m__dGxVX" data-theme>
  <div className="dev-static-features">
    <div className="dev-static-features__inner">

      {/* Online Marketing Consultation */}
      <div
        className="dev-static-features__set dev-static-features__set--marketing"
        style={{
          background: 'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)',
        }}
      >
        <div>
          <h3>Online Marketing Consultation</h3>
          <p>
            Join us for a collaborative marketing strategy session. Whether you need to refine an existing plan or boost a new project, our consultation services will guide you effectively.
          </p>
        </div>
        <img src={Consultation} alt="Online Marketing Consultation" />
      </div>

      {/* Marketing Plans */}
      <div
        className="dev-static-features__set dev-static-features__set--plans"
        style={{
          background: 'linear-gradient(to right top, #7dd181, #80d68d, #84da98, #89dfa3, #8ee3ae, #86e4bc, #82e4c8, #81e3d3, #7edde0, #85d6e7, #93cfe9, #a2c7e5)',
        }}
      >
        <div>
          <h3>Marketing Plans</h3>
          <p>
            Every successful business requires a robust marketing blueprint for survival. Beginning with a deep understanding of your target audience, we craft a clear and actionable plan, complete with user-friendly steps.
          </p>
        </div>
        <img src={Plan} alt="Marketing Plans" />
      </div>

      {/* Marketing Push */}
      <div
        className="dev-static-features__set dev-static-features__set--push"
        style={{
          background: 'linear-gradient(to right top, #0081a7, #008aac, #0093b0, #009bb4, #00a4b7, #30afb6, #4dbab5, #67c4b4, #93d3b5, #bae0bc, #deeec9, #fdfcdc)',
        }}
      >
        <div>
          <h3>Marketing Push</h3>
          <p>
            For critical marketing assistance beyond our ongoing support, we provide extra hours during important events and product launches, enhancing your success.
          </p>
        </div>
        <img src={Push} alt="Marketing Push" />
      </div>
    </div>

    <br/>
    <br/>
    <div className="dev-static-features__founder">
      <h1>Meet the founder</h1>
      <img src={Founder} width={'200px'} alt="Founder" />
    </div>
  </div>
</div>

      <hr />
    </>
  );
};

export default Services;
