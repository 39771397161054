import React from "react";
import { David, Joe, Prab, Wraps } from '../assets'
import "./Main.css";


const Testimony = () => {
  return (
    <main>
      <div className="overlay_o__hGGDw">
        <a
          className="overlay_o__scrim__pOPx_"
          title="Close overlay"
          href="/#"
        ></a>
      </div>
      <div
        className="layout layout_layout--bottom-0__sL_3a layout_layout--top-0__PTcMB"
        data-theme
        id="testmony"
      >
        <hr />
      </div>
      <div
        className="layout layout_layout--bottom-xl__LoXlO layout-top"
        data-theme
      >
        <div className="">
          <svg
            preserveAspectRatio="xMinYMin slice"
            viewBox="0 0 1440 920"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="c-hero__background"
          >
            <g filter="url(#filter5_f_27_1751)">
              <ellipse cx="462.5" cy={667} rx="370.5" ry={307} fill="#EDDFEF" />
            </g>
          </svg>

          <div className="static-parent">
            <h1 className="t-heading--gradient">What Our Clients Say About Us</h1>

            <div className="clients">
              <div>
                <h5>David Dhanoa</h5>
                <img src={David} />
                <h6>"Amazing video delivery! Ampere Media not only produced exceptional videos but also played a key role in our consultancy."</h6>
              </div>
              <div>
                <h5>Joey Kombat</h5>
                <img src={Joe} />
                <h6>"Looking forward to continued collaboration!"</h6>
              </div>
              <div>
                <h5>Prab</h5>
                <img src={Prab} />
                <h6>"I am genuinely thrilled to share my experience with Ampere Media. Working with them was an absolute pleasure from start to finish."</h6>
              </div>
              <div>
                <h5>Twisted Indian Wraps</h5>
                <img src={Wraps} />
                <h6>"I cannot express how impressed I am with the exceptional service provided by Ampere Media. From the initial consultation to the final delivery, every step was handled with utmost professionalism and care."</h6>
              </div>
            </div>

            {/* Center the "Book a demo" button */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
              <a
                className="o-button o-button--primary"
                data-gtm-key="button"
                href="https://odavqeqragw.typeform.com/to/DakWHqZy"
                target="_blank"
              >
                <span>Book a demo</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="layout layout_layout--bottom-0__sL_3a layout_layout--top-0__PTcMB"
        data-theme
      >

        <hr />
      </div>
    </main>
  );
};

export default Testimony;
