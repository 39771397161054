import React from "react";
import { Clothing, RealEstate, Immigration, Restraunt, Founder } from "../assets";
import "./Main.css";

const About = () => {
  return (
    <main>
      <div className="overlay_o__hGGDw">
        <a
          className="overlay_o__scrim__pOPx_"
          title="Close overlay"
          href="/#"
        ></a>
      </div>
      <div
        className="layout layout_layout--bottom-0__sL_3a layout_layout--top-0__PTcMB"
        data-theme
        id="about"
      >
        <hr />
      </div>
      <div
        className="layout layout_layout--bottom-xl__LoXlO layout-top"
        data-theme
      >
        <div className="static-differentiators">
          {/* <svg
            preserveAspectRatio="xMinYMin slice"
            viewBox="0 10 2010 740"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="c-hero__background"
          >
            <g filter="url(#filter5_f_27_1751)">
              <ellipse cx="462.5" cy={667} rx="370.5" ry={307} fill="#EDDFEF" />
            </g>
          </svg> */}

          <div className="static-differentiators-parent">
            <h2 className="t-heading--gradient">About Us</h2>

            <h2 className="t-lede">
              <b>Ampere Media </b>
              is your dedicated marketing partner, uniting creative minds and
              data-driven experts. We specialize in crafting compelling brand
              narratives and innovative digital campaigns to ensure your
              business thrives in today's ever-evolving marketplace. Let's
              illuminate your path to success together.
            </h2>
          </div>
          <div className="static-differentiators_sd__child__hxFQd">
            <div className="static-differentiators_sd__item__cnkAj">
              <img src={RealEstate} />

              <h3 className="t-heading t-heading--xs">Real Estate</h3>
            </div>
            <div className="static-differentiators_sd__item__cnkAj">
              <img src={Clothing} />
              <h3 className="t-heading t-heading--xs">Lifestyle Clothing</h3>
            </div>
            <div className="static-differentiators_sd__item__cnkAj">
              <img src={Immigration} />

              <h3 className="t-heading t-heading--xs">Immigration Services</h3>
            </div>
            <div className="static-differentiators_sd__item__cnkAj">
              <img src={Restraunt} />

              <h3 className="t-heading t-heading--xs">Restaurant</h3>
            </div>

          </div>
        </div>
      </div>
      <div
        className="layout layout_layout--bottom-0__sL_3a layout_layout--top-0__PTcMB"
        data-theme
      >
        {/* <h2>Meet the Founder</h2>
            <img src={Founder} width={200} /> */}
        <hr />
      </div>
    </main>
  );
};

export default About;
