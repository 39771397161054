import React from "react";
import { Navbar, Header, About, Services, Footer, Testimony } from "./components";

const App = () => {

  return (
    <div>
      <svg
        preserveAspectRatio="xMinYMin slice"
        viewBox="0 0 1440 720"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="c-hero__background"
      >
        <g filter="url(#filter1_f_27_1751)">
          <ellipse
            cx={442}
            cy={801}
            rx={313}
            ry={559}
            transform="rotate(90 442 801)"
            fill="#EDDFEF"
          />
        </g>
        <g opacity="0.7" filter="url(#filter2_f_27_1751)">
          <ellipse
            cx="1536.81"
            cy="266.114"
            rx="156.28"
            ry="310.061"
            transform="rotate(-170.768 1536.81 266.114)"
            fill="#EDDFEF"
          />
        </g>
        <g opacity="0.3" filter="url(#filter3_f_27_1751)">
          <ellipse
            cx="1355.43"
            cy="100.263"
            rx="156.28"
            ry="169.385"
            transform="rotate(-170.768 1355.43 100.263)"
            fill="#EDDFEF"
          />
        </g>
        <g opacity="0.7" filter="url(#filter4_f_27_1751)">
          <ellipse
            cx="317.99"
            cy="700.475"
            rx="455.016"
            ry="169.385"
            transform="rotate(-170.768 317.99 700.475)"
            fill="#EDDFEF"
          />
        </g>
        <g filter="url(#filter5_f_27_1751)">
          <ellipse cx="462.5" cy={667} rx="370.5" ry={307} fill="#EDDFEF" />
        </g>
        <g filter="url(#filter6_f_27_1751)">
          <ellipse
            cx="1272.36"
            cy="-11.8801"
            rx="370.5"
            ry={307}
            transform="rotate(-150 1272.36 -11.8801)"
            fill="#EDDFEF"
          />
        </g>
        <rect
          y={293}
          width={1440}
          height={427}
          fill="url(#paint7_linear_27_1751)"
        />
        <defs>
          <filter
            id="filter0_f_27_1751"
            x={-473}
            y={-86}
            width={970}
            height={1270}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter1_f_27_1751"
            x={-289}
            y={316}
            width={1462}
            height={970}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter2_f_27_1751"
            x="1202.69"
            y="-212.973"
            width="668.244"
            height="958.173"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter3_f_27_1751"
            x="1026.77"
            y="-240.82"
            width="657.315"
            height="682.166"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter4_f_27_1751"
            x="-303.968"
            y="345.997"
            width="1243.92"
            height="708.957"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter5_f_27_1751"
            x={-80}
            y={200}
            width={1085}
            height={958}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>
          <filter
            id="filter6_f_27_1751"
            x="744.591"
            y="-507.952"
            width="1055.54"
            height="992.144"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={86}
              result="effect1_foregroundBlur_27_1751"
            />
          </filter>

          <clipPath id="clip0_27_1751">
            <rect width={1440} height={720} fill="white" />
          </clipPath>
        </defs>
      </svg>
        <Navbar />
      <Header />
      <About />
      <Testimony />
      <Services />
      <Footer />
    </div>
  );
};

export default App;
